import React from "react";
import ReactDOM from "react-dom";

import "./styles.css";

class App extends React.Component {
  componentDidMount() {
    const domain = "im.enssi.cn";
    let url = decodeURI(window.location.href);
    if (url.indexOf("?") > 1) {
      var paraString = url.substring(url.indexOf("?") + 1, url.length).split("&");
      var meeting_id = paraString[0].substring(paraString[0].indexOf("=") + 1);
      var display_name = paraString[1].substring(paraString[1].indexOf("=") + 1);
    }
    console.log(meeting_id, display_name);
    const options = {
      width: window.screen.width,
      height: window.screen.height,
      parentNode: document.querySelector("#meet"),
      interfaceConfigOverwrite: {
        DEFAULT_BACKGROUND: "#5baaff",
        SHOW_JITSI_WATERMARK: false,
        noSsl: true,
        JITSI_WATERMARK_LINK: "#",
        SHOW_BRAND_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        SHOW_POWERED_BY: false,
        TOOLBAR_BUTTONS: [],
      },
      roomName: meeting_id,
      userInfo: {
        displayName: display_name,
      },
    };
    const api = new window.JitsiMeetExternalAPI(domain, options);
    window.$api = api;

    window.native = this;

    // window.api.invite([
    //     {
    //       type: "phone",
    //       number: "0015600663349",
    //     },
    //   ])
    //   .then(() => {
    //     // success
    //   })
    //   .catch(() => {
    //     // failure
    //   });

    //window.api.dispose();
  }

  hangup() {
    window.$api.dispose();
  }
  invite(tel) {
    window.$api
      .invite([
        {
          type: "phone",
          number: "0" + tel,
        },
      ])
      .then(() => {})
      .catch(() => {});
  }
  render() {
    return (
      <div className="App">
        <div id="meet" />
      </div>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
